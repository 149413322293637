/* You can add global styles to this file, and also import other style files */
 /* regular style toast */
 @import '~ngx-toastr/toastr';

 /* // bootstrap style toast
 // or import a bootstrap 4 alert styled design (SASS ONLY)
 // should be after your bootstrap imports, it uses bs4 variables, mixins, functions */
 /* @import '~ngx-toastr/toastr-bs4-alert'; */
 
 /* // if you'd like to use it without importing all of bootstrap it requires */
 /* @import '~bootstrap/scss/functions';
 @import '~bootstrap/scss/variables';
 @import '~bootstrap/scss/mixins';
 @import '~ngx-toastr/toastr-bs4-alert'; */

 .btns__deposit_withdrawal {
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .btns__deposit_withdrawal .dw {
    width: 100%;
    text-align: center;
 }
 .btns__deposit_withdrawal .dw a span,  .btns__deposit_withdrawal .dw a i {
    color: #fff;
 }
li.deposit a, .btns__deposit_withdrawal .deposit {background-color: brown;}
li.withdraw a, .btns__deposit_withdrawal .withdraw { background-color: green;}

 .bg-slate-900 {
    /*--tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;*/
    background: url('assets/img/bg-desk.png') !important;
}
.copy-text {
    text-align: center;
    color: #fff;
    margin-top: 10px;
    font-size: 14px;
}
.modalCustom .demologin{
    width: 100%;
    margin: 0 auto;
    border-radius: 5px !important;
    height: 50px;
    display: flex !important;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 900;
    margin-top: 10px;
}
.modalCustom .modal-content {
    background: #000;
    border-radius: 10px;
}
.modalCustom .demologin-loginblock {
    display: none !important;
}
.modalCustom .modal-content button.close {
    color: #fff;
    opacity: 1;
    font-size: 40px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 0;
    line-height: 1;
}
 .text-dlt {
    opacity: 0.7;
  }
 .bgimage{
	position: inherit !important;
}
 .text-green {
    color: #28a745;
    font-weight: 900 !important
}
.text-red {
    color: #ef4e46;
    font-weight: 900 !important
}
.no-overlay #overlay {
    background: transparent !important;
}

.full-overlay .loader {
    min-height: auto !important;
    position: initial !important;
}

iframe {
    width: 100%;
    height: 100%;
    min-height: 300px;
}



/* PAGINATION CSS */
.pagination {justify-content: flex-end;}

.pagination nav{
    /* margin:0 auto; */
    padding-top:10px;
  }
  /*.pagination.pagination-circle .page-item.active .page-link {
      border-radius: 50%;
  }*/
  /*.pagination .page-item .page-link:hover {
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
      border-radius: .125rem;
      background-color: #eee;
  }*/
  .pagination.pg-blue .page-item.active .page-link:hover {
      background-color: #272c33;
  }
  .pagination .page-item.active .page-link {
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
      border-radius: .125rem;
      background-color: #272c33;
      color: #fff;
  }
  .pagination .page-item .page-link {
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
      outline: 0;
      border: 0;
      background-color: transparent;
      font-size: .9rem;
      color: #999;
      font-family: tahomabd;
  }
  /*.pagination.pagination-circle .page-item .page-link:hover {
      border-radius: 50%;
  }*/
  /*.pagination.pagination-circle .page-item .page-link {
      margin-left: 2px;
      margin-right: 2px;
      border-radius: 50%;
  }*/
  .pagination .page-item.active .page-link{
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  }
  .page-link{
    border:none!important;
  }

/* PAGINATION CSS */

span.fav-market {
    display: inline-block;
    position: relative;
    /* vertical-align: middle; */
    font-size: 15px;
    color: #ffff;
    padding: 0 5px 0 5px;
    /* line-height: 3; */
}

/*horse race*/
.race-inner-tab ul.nav {
    width: auto;
}
.race-inner-tab ul.nav li a {
    padding: 4px 16px;
}
.bet-table-row {
    padding: 6px 8px;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    box-shadow: 0px 0px 5px 0px #373a4d2b;
}
.horse-table .bet-table-row {
    padding: 6px 8px 1px 8px;
    font-size: 13px;
    font-family: tahomabd;
}
.game-title {
    /* width: calc(100% - 492px); */
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
}
.game-icons {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: flex-end;
}
.horse-table .game-name {
    /* width: calc(100% - 30px); */
}   
.team-name {
    text-align: left;
    max-height: 20px;
    line-height: 20px;
    margin-bottom: 0;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    max-width: 100%;
}
.horse-time-detail {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 400px);
}
.horse-time-detail a {
    display: flex;
}
.horse-time-detail span {
    background: #272c33;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.events_odds img {
    height: 21px;
    /* margin-right: 5px; */
} 
.events_odds img {
    border: 1px solid var(--text-table);
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.events_odds .horse-attr {
    background: #d9d9d9;
    padding: 0 5px;
    margin-left: 2px;
    border-radius: 4px;
    margin-bottom: 2px;
    font-family: tahomabd;
    font-size: 13px;
}
.events_odds .jockey-detail {
    font-size: 13px;
    display: flex;
    flex-wrap: wrap;
}

.events_odds .jockey-img {
    margin-top: 4px;
}
@media only screen and (min-width: 1280px) {
    .d-none-desktop {
        display: none!important;
    }
    .events_odds {
        align-items: flex-start !important;
    }
}

@media (max-width: 767px) {
    .event-names {
        align-items: center;
    }
    .team-name {
        text-align: center !important;
    }
    .game-title {
        margin-bottom: 5px;
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
        justify-content: center;
        background: #ccc;
    }
    .bet-table-row { 
        padding: 0;
    }
}

@media only screen and (min-width: 320px) and (max-width: 1279px) {
    .d-none-mobile {
        display: none!important;
    }
    .events_odds .horse-attr {
        font-size: 11px;
    }
}
